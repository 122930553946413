import React, { useState } from "react";

import "./styles.scss";

const IndustriesSubmenu = ({ industries }) => {
  const [selectedIndustry, setSelectedIndustry] = useState("financial");
  const industriesList = industries.map((ind) => (
    <li onClick={() => setSelectedIndustry(ind.iconKey)}>
      <a
        href={`#${ind.iconKey}`}
        className={`${selectedIndustry === ind.iconKey ? "selected" : ""}`}
      >
        {ind.title}
      </a>
    </li>
  ));
  return <ul className="industries-submenu">{industriesList}</ul>;
};

export default IndustriesSubmenu;
