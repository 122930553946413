import React from "react";
import { graphql } from "gatsby";
import { Container, Col, Row, Hidden } from "react-grid-system";
import { Helmet } from "react-helmet";

import Header from "Commons/Header";
import Footer from "Commons/Footer";
import PageHeader from "Commons/PageHeader";
import TrustedBy from "Commons/TrustedBy";
import useLang from "Commons/Hooks/useLang";

import Financial from "Images/industries/financial.svg";
import Federal from "Images/industries/federal.svg";
import Enterprise from "Images/industries/enterprise.svg";
import Retail from "Images/industries/retail.svg";
import Technology from "Images/industries/technology.svg";
import Healthcare from "Images/industries/healthcare.svg";
import NPO from "Images/industries/npo.svg";

import SingleIndustry from "Components/Industries/SingleIndustry";
import IndustriesSubmenu from "Components/Industries/IndustriesSubmenu";

const icons = {
  financial: <Financial />,
  federal: <Federal />,
  enterprise: <Enterprise />,
  retail: <Retail />,
  technology: <Technology />,
  healthcare: <Healthcare />,
  npo: <NPO />,
};

const IndustriesPage = ({ data }) => {
  const [lang] = useLang();
  const { pageTitle, title, industriesList } =
    data.allContentfulIndustriesTexts.nodes[lang];
  const { companiesThatTrust } = data.allContentfulCommonTexts.nodes[lang];

  const mappedIndustries = industriesList.map((ind) => ({
    ...ind,
    icon: icons[ind.iconKey],
  }));

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header />
      <PageHeader text={title} />
      <Container>
        <Row>
          <Hidden xs sm md>
            <Col xs={12} lg={3}>
              <IndustriesSubmenu industries={industriesList} />
            </Col>
          </Hidden>
          <Col xs={12} lg={6}>
            {mappedIndustries.map((ind) => (
              <SingleIndustry
                iconKey={ind.iconKey}
                title={ind.title}
                icon={ind.icon}
                description={ind.description}
              />
            ))}
          </Col>
        </Row>
      </Container>
      <TrustedBy text={companiesThatTrust} />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getIndustriesPageTexts {
    allContentfulIndustriesTexts {
      nodes {
        pageTitle
        title
        industriesList {
          description
          iconKey
          title
        }
      }
    }
    allContentfulCommonTexts {
      nodes {
        companiesThatTrust
      }
    }
  }
`;

export default IndustriesPage;
