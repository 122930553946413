import React from "react";

import "./styles.scss";

const SingleIndustry = ({ title, icon, description, iconKey }) => {
  return (
    <div className="single-industry" id={iconKey}>
      <div>{icon}</div>
      <div className="single-industry__text">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default SingleIndustry;
